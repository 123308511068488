<template>
  <div
    v-if="booking.activityType === 'formula'"
    class="pull-left"
  >
    <d-button
      :class="innerWidth ? 'ml-4' : 'ml-0 mr-0'"
      class="font-text-title left-footer-link"
      text="information-and-form"
      @on:button-click="displayFormModal()"
    />

    <formula-form-modal
      :key="`form-modal-${key}`"
      :mode="mode"
      :booking="booking"
      :show-form="showForm"
      :timetable-block-price="timetableBlockPrice"
      class="custom-sub-modal"
      @hidden="showForm = false"
    />
  </div>
</template>
<script>
export default {
  components: {
    FormulaFormModal: () => import('@custom/formula/FormulaFormModal')
  },
  data: () => ({
    key: 0,
    showForm: false,
    mode: 'create'
  }),
  props: ['timetableBlockPrice', 'innerWidth', 'booking'],
  methods: {
    displayFormModal() {
      this.key++;

      this.$nextTick(() => {
        this.showForm = true;
      });
    }
  }
}
</script>
<style scoped lang="scss">
.left-footer-link {
  color: #092772;
  cursor: pointer;
  font-size: 15px;
  text-decoration: none;
  font-family: Avenir;
  font-weight: bold;
}
</style>
