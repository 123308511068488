<template>
  <div
    class="btns-display"
    :class="flexPosition"
  >
    <formula-action
      v-if="timetableBlockPrice"
      :booking="booking"
      :inner-width="innerWidth"
      :timetable-block-price="timetableBlockPrice"
    />
    <match-actions
      :is-busy="isBusy"
      :booking="booking"
      :inner-width="innerWidth"
    />
    <booking-actions
      :is-busy="isBusy"
      :booking="booking"
      :inner-width="innerWidth"
      @hidden="$emit('hidden')"
      @on:booking:update="$emit('on:booking:update')"
    />
  </div>
</template>

<script>

import FormulaAction from "@views/planning/schedule/playgrounds/bookings/details/footer-actions/FormulaAction";
export default {
  components: {
    FormulaAction,
    MatchActions: () => import("@views/planning/schedule/playgrounds/bookings/details/footer-actions/MatchActions"),
    BookingActions: () => import("@views/planning/schedule/playgrounds/bookings/details/footer-actions/BookingActions"),
  },
  props: ['timetableBlockPrice', 'position', 'innerWidth', 'isBusy', 'booking'],
  computed: {
    flexPosition() {
      if (this.position === 'right' && this.innerWidth) {
        return 'justify-content-start mr-0';
      } else if (this.innerWidth) {
        return this.booking.activityType !== 'sport' && this.booking.activityType !== 'formula' ? 'justify-content-end' : 'justify-content-between mr-0';
      } else {
        return 'justify-content-between mr-1 ml-1';
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.btns-display {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: flex-end;
  bottom: 75px;
  width: -webkit-fill-available;
  background-color: white;
  padding: 15px 0 5px 0;
  border-top: 2px solid #EDF0F3;
}

@media only screen and (max-width: 700px) {
  .btns-display {
    justify-content: space-evenly;
  }
}

</style>
